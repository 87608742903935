import {useState} from 'react';
import {useBetween} from 'use-between';

function useSearchDialog() {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');

  return {
    isOpen,
    setIsOpen,
    query,
    setQuery
  };
}

export default () => useBetween(useSearchDialog);


