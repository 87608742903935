'use client';

import React from "react";
import PropTypes from 'prop-types';

import Grid from "@mui/material/Grid";
import {Typography} from "@mui/material";
import Divider from "@mui/material/Divider";

import RecipeTile from "src/components/recipe/recipe-tile";

function HomepagePromotedCategories({promotedCategoryList}) {
  return (
    <>
    {
      promotedCategoryList
        .filter(promotedCategory => promotedCategory.homepagePromotedRecipes)
        .map(promotedCategory => (
        <div key={`homepage-promoted-category-${promotedCategory.id}`}>
          <Typography variant="h3" color="primary.main">{promotedCategory.name}</Typography>
          <Grid container spacing={2}>
            {
              promotedCategory.homepagePromotedRecipes?.slice(0, 4)
                .map(recipe => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={`homepage-promoted-category-${promotedCategory.id}-${recipe.pimcoreRecipeId}`}>
                    <RecipeTile
                      key={`recipe-tile-${promotedCategory.id}-${recipe.pimcoreRecipeId}`}
                      recipe={recipe}
                    />
                  </Grid>
                ))
            }
          </Grid>
          <Divider sx={{ borderStyle: 'none', my: 1 }} />
        </div>
      ))
    }
    </>
  );
}

HomepagePromotedCategories.propTypes = {
  promotedCategoryList: PropTypes.array
};

export default HomepagePromotedCategories;
