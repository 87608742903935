'use client';

import React from "react";
import {m} from 'framer-motion';

import Box from '@mui/material/Box';
import {alpha} from '@mui/material/styles';
import Container from '@mui/material/Container';

import useSearchDialog from "src/hooks/search/use-search-dialog";

import {bgGradient} from 'src/theme/css';

import {MotionContainer} from 'src/components/animate';
import KuliguliSearchInput from 'src/components/input/kuliguli-search-input';

import uuidv4 from "../../utils/uuidv4";
import {varFade} from "../../components/animate/variants";

export default function HomepageHero() {
  const {setIsOpen} = useSearchDialog();

  const openSearchDialog = () => {
    setIsOpen(true);
  }

  return (
    <Box
      sx={{
        ...bgGradient({
          startColor: alpha('#888888', 0.3),
          endColor: alpha('#888888', 0.08),
          imgUrl: '/assets/banners/homepage_banner.jpg',
        }),
        height: {md: 560},
        py: {xs: 10, md: 0},
        mt: -5,
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Container component={MotionContainer}>
        <Box
          sx={{
            bottom: {md: 200},
            position: {md: 'absolute'},
            textAlign: {xs: 'center'},
            width: {md: '100vw'},
            left: 0,
            right: 0
          }}
        >
          <m.div variants={varFade().inDown}>
            <Box
              sx={{
                display: 'inline-block',
                width: {xs: '80%', md: 600}
              }}
            >
              <Box
                sx={{
                  typography: 'h1',
                  overflow: 'hidden',
                  display: 'inline-flex',
                  color: '#fff',
                  mb: 3
                }}
              >
                Masz ochotę na coś pysznego?
              </Box>
              <br/>
              <KuliguliSearchInput
                id="homepage-search-input"
                fullWidth
                size="large"
                onFocus={openSearchDialog}
                key={uuidv4()}
              />
            </Box>
          </m.div>
        </Box>
      </Container>
    </Box>
  );
}
