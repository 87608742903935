import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import ListItemText from '@mui/material/ListItemText';
import CardActionArea from "@mui/material/CardActionArea";

import {paths} from 'src/routes/paths';
import {RouterLink} from 'src/routes/components';

import {fDateTime} from 'src/utils/format-time';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------
const StyledCardActionArea = styled(CardActionArea)(({theme}) => ({
  '&:hover': {
    '& .MuiCardActionArea-focusHighlight': {
      opacity: '0.01',
    },
  }
}));

const StyledCard = styled(Card)(({theme}) => ({
  boxShadow: 'rgba(145, 158, 171, 0.16) 0px 1px 2px 0px',
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  '&:hover': {
    boxShadow: 'rgba(145, 158, 171, 0.16) 0px 20px 40px -4px',
  }
}));

export default function RecipeTile({recipe, onTileClick, ...others}) {
  const {
    pimcoreRecipeId: id,
    name,
    photo,
    createdAt,
    forHowManyPersonLabel: forHowManyPerson,
    timeConsumingLabel: timeConsuming,
    displayCount
  } = recipe;

  const views = displayCount ?? 0;
  const ratingNumber = (Math.round(recipe.ratingValue * 10) / 10).toFixed(1);

  const renderRating = ratingNumber > 0
    ? (
      <Stack
        key={`stack-rating-${id}`}
        direction="row"
        alignItems="center"
        sx={{
          top: 8,
          right: 8,
          zIndex: 9,
          borderRadius: 1,
          position: 'absolute',
          p: '2px 6px 2px 4px',
          typography: 'subtitle2',
          bgcolor: 'warning.lighter',
        }}
      >
        <Iconify icon="eva:star-fill" sx={{color: 'warning.main', mr: 0.25}}/> {ratingNumber}
      </Stack>
    )
    : '';

  const renderImages = (
    <Stack
      key={`stack-images-${id}`}
      spacing={0.5}
      direction="row"
      sx={{
        p: (theme) => theme.spacing(1, 1, 0, 1),
      }}
    >
      <Stack key={`stack-images-rating-${id}`} flexGrow={1} sx={{position: 'relative'}}>
        {renderRating}
        <Image src={photo?.url} sx={{borderRadius: 1, height: 164, width: 1}}/>
      </Stack>

    </Stack>
  );

  const renderTexts = (
    <ListItemText
      sx={{
        p: (theme) => theme.spacing(2.5, 2.5, 2, 2.5),
      }}
      primary={`Opublikowano: ${fDateTime(createdAt * 1000)}`}
      secondary={name}
      primaryTypographyProps={{
        typography: 'caption',
        color: 'text.disabled',
      }}
      secondaryTypographyProps={{
        mt: 1,
        noWrap: true,
        component: 'span',
        color: 'text.primary',
        typography: 'subtitle1',
      }}
    />
  );

  const renderInfo = (
    <Stack
      key={`stack-info-${id}`}
      spacing={1.5}
      sx={{
        position: 'relative',
        p: (theme) => theme.spacing(0, 2.5, 2.5, 2.5),
      }}
    >
      {[
        {
          label: forHowManyPerson,
          icon: <Iconify icon="solar:users-group-rounded-bold" sx={{color: 'primary.main'}}/>,
        },
        {
          label: timeConsuming,
          icon: <Iconify icon="solar:clock-circle-bold" sx={{color: 'info.main'}}/>,
        },
        {
          label: `${views} wyświetleń`,
          icon: <Iconify icon="carbon:view-filled" sx={{color: 'error.main'}}/>,
        },
      ].map((item, index) => (
        <Stack
          key={`stack-item-${id}-${index}`}
          spacing={1}
          direction="row"
          alignItems="center"
          sx={{typography: 'body2'}}
        >
          {item.icon}
          {item.label}
        </Stack>
      ))}
    </Stack>
  );

  return (
    <StyledCard {...others}>
      <StyledCardActionArea component={RouterLink} href={paths.recipe.details(id)} onClick={onTileClick}>
        {renderImages}

        {renderTexts}

        {renderInfo}
      </StyledCardActionArea>
    </StyledCard>
  );
}

RecipeTile.propTypes = {
  recipe: PropTypes.object,
  onTileClick: PropTypes.func
};
