'use client';

import React from "react";
import {m} from 'framer-motion';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import {alpha} from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import useTheme from "@mui/material/styles/useTheme";

import {paths} from "src/routes/paths";
import {RouterLink} from "src/routes/components";

import {bgGradient} from 'src/theme/css';

import {MotionContainer} from 'src/components/animate';
import {varFade} from "src/components/animate/variants";
import Carousel, {useCarousel, CarouselArrowIndex} from "src/components/carousel";

import Image from "../../components/image";

const SLIDES = [
  {
    id: 1,
    title: 'Pierwszy slajd',
    description: 'Zostań gwiazdą KuliGuli i dodawaj przepisy razem z nami!',
    coverUrl: '/assets/banners/homepage_slider_3.jpg',
    buttonLabel: 'Dodaj przepis',
    buttonUrl: paths.recipe.add

  },
  {
    id: 2,
    title: 'Drugi slajd',
    description: 'Testowy opis',
    coverUrl: '/assets/banners/homepage_slider_2.jpg',
  },
  {
    id: 3,
    title: 'Trzeci slajd',
    coverUrl: '/assets/banners/homepage_slider_1.jpg',
    buttonLabel: 'Zobacz więcej',
    buttonUrl: paths.recipe.browse
  }
];

export default function HomepageSlider() {
  const carousel = useCarousel({
    speed: 800,
    autoplay: true
  });


  return (
    <Card>
      <Carousel ref={carousel.carouselRef} {...carousel.carouselSettings}>
        {SLIDES.map((item, index) => (
          <CarouselItem key={item.id} item={item} active={index === carousel.currentIndex}/>
        ))}
      </Carousel>
      <CarouselArrowIndex
        index={carousel.currentIndex}
        total={SLIDES.length}
        onNext={carousel.onNext}
        onPrev={carousel.onPrev}
      />
    </Card>
  );
}

function CarouselItem({item, active}) {
  const theme = useTheme();

  const {coverUrl, title, buttonLabel, buttonUrl} = item;
  const showButton = !!buttonLabel && !!buttonUrl;

  const variants = varFade().inRight;

  return (
    <Paper sx={{position: 'relative'}}>
      <Image dir="ltr" alt={title} src={coverUrl} ratio="16/9"/>

      <Box
        sx={{
          top: 0,
          width: 1,
          height: 1,
          position: 'absolute',
          ...bgGradient({
            direction: 'to top',
            startColor: `${theme.palette.grey[900]} 0%`,
            endColor: `${alpha(theme.palette.grey[600], 0)} 100%`,
          }),
        }}
      />

      <CardContent
        component={MotionContainer}
        animate={active}
        action
        sx={{
          textAlign: 'center',
          width: '100%',
          left: 0,
          right: 0,
          top: '50%',
          position: 'absolute',
          color: 'common.white',
        }}
      >
        <m.div variants={variants}>
          <Typography variant="h2" gutterBottom>
            {item.title}
          </Typography>
        </m.div>

        <m.div variants={variants}>
          <Typography variant="body1" noWrap gutterBottom>
            {item.description}
          </Typography>
        </m.div>

        {showButton && <m.div variants={variants}>
          <Button component={RouterLink} href={buttonUrl} variant="contained" color='primary' sx={{mt: 3}}>
            {buttonLabel}
          </Button>
        </m.div>
        }
      </CardContent>
    </Paper>
  );
}

CarouselItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.bool
};
