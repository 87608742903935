import React from "react";
import PropTypes from 'prop-types';

import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import {Typography} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import CardActionArea from "@mui/material/CardActionArea";

import {RouterLink} from "src/routes/components";


function CardButton({icon, title, link, ...others}) {
  return (
    <Stack component={Card} direction="column"
           {...others} sx={{width: 130}}>
      <CardActionArea component={RouterLink} href={link} sx={{p: 2, textAlign: 'center'}}>
        <Avatar
          alt={title}
          src={icon}
          variant="square"
          sx={{width: 64, height: 64, display: 'inline-block'}}
        />
        <Divider sx={{borderStyle: 'solid', my: 1}}/>
        <Typography variant="h6" color="primary.main">{title}</Typography>
      </CardActionArea>
    </Stack>
  );
}

CardButton.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
}

export default CardButton;
