'use client';

import React from "react";
import PropTypes from 'prop-types';

import Stack from "@mui/material/Stack";

import {paths} from "src/routes/paths";

import CardButton from "src/components/card-button";

function HomepageCategories({recipeCategoryList}) {
  const preparedCategoriesData = recipeCategoryList
    .filter(recipeCategory => recipeCategory.showOnHomepage)
    .map(recipeCategory => ({
        code: recipeCategory.value,
        title: recipeCategory.label,
        link: paths.recipe.browseWithParams({categories: recipeCategory.value}),
        icon: recipeCategory?.homepageIcon?.url
      })
  )

  return (
    <Stack key="homepage-categories" direction="row" useFlexGap sx={{ flexWrap: "wrap", justifyContent: "space-evenly" }} spacing={2}>
      {
        preparedCategoriesData.map(category => (
          <CardButton key={`${category.code}-card-button`} title={category.title} link={category.link} icon={category.icon} />
        ))
      }
    </Stack>
  );
}

HomepageCategories.propTypes = {
  recipeCategoryList: PropTypes.array
};

export default HomepageCategories;
